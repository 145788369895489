@use "shared";

@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: shared.$default-font-family !important;
  background-image: linear-gradient(to top, #1f1d47, #0a0b43);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.background_gradient_color {
  background-image: linear-gradient(180deg, hsla(246, 64%, 27%, 1), hsla(247, 71%, 25%, 1)) !important;
}



::placeholder {
  opacity: 1 !important;
  color: #adadd0 !important;
}