
.style_all_content{
    display: flex;
    text-align: center;
    justify-content: center;
}
.content {
    top: 50%;
    left: 50%;
    width: 500px;
    padding: 20px;
    margin-bottom: 2%;
    background-image: linear-gradient(225deg,#6a45d6,#a034d1);
    box-shadow: 0px 4px 12px rgba(183, 183, 183, 0.4);
    border-radius: 20px;

}
.title {
    font-family: Roboto;
    font-weight: 600;
    font-size: 30px;
    color:#fdfcfe;

    text-align: center;
}
.form_inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.style_input {
    font-family: Roboto;
    font-weight: 400;
    font-size: 20px;
    width: 400px;
    height: 45px;
    border-radius: 10px;
    padding-left: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px solid #A9AEB2;
    cursor: pointer;
}

.style_input_button {
    margin-top: 20px;
    margin-bottom: 15px;
    font-family: Roboto;
    font-weight: 700;
    color:#36454F;
    font-size: 24px;
    background-color: #ffa726;
    border-color: #ffa726;
    width: 170px;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;
}
.style_input_button:hover{
    background-color: #FDCE0E;
}

.loginWrapper {
    background: url(../../images/background.jpg) no-repeat center center fixed;
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 100vh;
    display: relative;
    padding-top: 185px;
}

.buttonWrapper {
    display: flex;
    padding-top: 120px;
}

.loginLogo {
    margin-top: 15px;
}

.loginBottomBox {
    bottom: 60px;
    right: calc(50% - 75px);
}

@media only screen and (max-height: 750px) {
    .loginWrapper {
        padding-top: 125px;
    }
}

@media only screen and (max-height: 650px) {
    .loginWrapper {
        padding-top: 100px;
    }
}

@media only screen and (max-height: 600px) {
    .buttonWrapper {
        padding-top: 100px;
    }

    .loginWrapper {
        padding-top: 65px;
    }
}

@media only screen and (max-height: 550px) {
    .buttonWrapper {
        padding-top: 75px;
    }

    .loginWrapper {
        padding-top: 50px;
    }
}
