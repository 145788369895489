@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  text-decoration: none;
}

.App {
	background: var(--main-background-color);
}

:root {
	/* Colors */
	--main-page-color: #0D0F1E;
	--main-background-color: #121625;
	--main-white-color: #ffffff;
	--main-blue-color: #4C82FB;
	--main-podBlue-color: #1D2C50;
	--main-blueBackground-color: #1D2B54;
	--main-btnWindowOne-color: #293249; 
	--main-inputWindow-color: #141B2B; 
	--main-gray-color: #858DA7; 
	--main-grayInput-color: #5E6887;
	--main-violet-color: #9148FA;

	/* Font-weight */
	--main-fontVeryBold-weight: 700;
	--main-fontBold-weight: 600;
	--main-fontPlusNormal-weight: 500;
	--main-fontNormal-weight: 400;

	/* Font-family */
	--main-inter-font-family: 'Inter';

	/* Width-dec-stop */
	--main-width-percent: 100%;
	--main-width-container: 1200px;
	--main-width-container-max: 1920px;
}

.MuiTextField-root {
	width: 24ch;
}
